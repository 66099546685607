import React, { useEffect, useState, useCallback } from "react";
import { useGesture } from "react-use-gesture";
import cx from "classnames";
import {
  motion,
  useMotionValue,
  useSpring,
  AnimatePresence,
} from "framer-motion";
import useEmblaCarousel from "embla-carousel-react";

import { ModuleWrapper } from "../moduleWrapper";
// import DragCursor from "@components/ui/dragCursor";
// import CloseIcon from "@svg/close.svg";
import ChevronPrev from "@svg/chevron-prev.svg";
import ChevronNext from "@svg/chevron-next.svg";
import { SanityImage } from "@components/sanity/sanityImage";
// import PortalModal from "@components/ui/portalModal";
// import PortableTextBlock from "@components/sanity/portableTextBlock";

const cursorVars = {
  visible: {
    scale: 1,
    opacity: 1,
  },
  dragging: {
    scale: 0.5,
    opacity: 1,
  },
  hidden: {
    scale: 0.2,
    opacity: 0,
  },
};

const cursorTextVars = {
  visible: {
    opacity: 1,
  },
  dragging: {
    opacity: 0,
  },
  hidden: {
    opacity: 0,
  },
};

const PrevButton = ({ enabled, onClick }) => (
  <button
    className={cx(
      "md:hidden transition-all ease-in-out duration-300 mr-4 w-12 h-12 rounded-full cursor-pointer touch-manipulation bg-red bg-opacity-10 ",
      {
        "opacity-20": !enabled,
      }
    )}
    onClick={onClick}
    disabled={!enabled}
  >
    <ChevronPrev className="m-auto" />
  </button>
);

const NextButton = ({ enabled, onClick }) => (
  <button
    className={cx(
      "md:hidden transition-all ease-in-out duration-300 ml-4 w-12 h-12 rounded-full cursor-pointer touch-manipulation bg-red bg-opacity-10 ",
      {
        "opacity-20": !enabled,
      }
    )}
    onClick={onClick}
    disabled={!enabled}
  >
    <ChevronNext className="m-auto ]" />
  </button>
);

const BioCard = ({ image, name, position }) => {
  //   const [isOpen, setIsOpen] = React.useState(false);

  //   const handleOpen = () => setIsOpen(true);
  //   const handleClose = () => setIsOpen(false);

  //   const ref = React.useRef();
  //   useOnClickOutside(ref, handleClose);

  return (
    <div className="w-full flex-shrink-0 bg-white rounded-lg cursor-pointer overflow-hidden md:w-1/2 lg:w-1/4 cursor-none">
      <div className="aspect-w-5 aspect-h-4 relative w-full">
        <SanityImage
          image={image}
          className="absolute w-full h-full object-cover top-0 left-0"
        />
      </div>
      <div className="px-4 py-6 lg:p-6">
        <h4 className="text-2xl text-red mb-half ">{name}</h4>
        <h5 className="text-xl text-gray-600  mb-half">{position}</h5>
        {/* <button className=" text-red font-bold" onClick={handleOpen}>
            Read Bio
          </button> */}
      </div>
    </div>
  );
};

const BioCarousel = ({ config, items, heading }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    containScroll: true,
    align: "start",
    dragFree: false,
  });

  const [cusorActive, setCursorActive] = useState(false);
  const [isDrag, setDrag] = useState(false);

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();

    embla.on("select", onSelect);
  }, [embla, onSelect]);

  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);

  const springConfig = { damping: 40, stiffness: 300 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  const bind = useGesture({
    onHover: ({ hovering }) => setCursorActive(hovering),
    onDrag: ({ dragging }) => setDrag(dragging),
    onMove: ({ values }) => {
      cursorX.set(values[0] - 40);
      cursorY.set(values[1] - 40);
    },
  });

  return (
    <>
      <AnimatePresence>
        {cusorActive && (
          <motion.div
            style={{
              width: "80px",
              height: "80px",
              translateX: cursorXSpring,
              translateY: cursorYSpring,
            }}
            className="fixed left-0 top-0 rounded-full z-50 pointer-events-none cursor-none flex items-center cards-center justify-center drag-cursor bg-red md:bg-red-light text-red"
            variants={cursorVars}
            initial="hidden"
            animate={isDrag ? "dragging" : "visible"}
            exit="hidden"
          >
            <motion.span className="font-medium" variants={cursorTextVars}>
              &lt; Drag &gt;
            </motion.span>
          </motion.div>
        )}
      </AnimatePresence>
      {/* <DragCursor> */}
      <ModuleWrapper
        {...config}
        className="px-gutter overflow-x-hidden relative group"
        revealAnimation
      >
        <h1 className="text-7xl text-center mb-10 text-red">{heading}</h1>

        <div className="py-6" ref={viewportRef} {...bind()}>
          <div className="flex gap-x-4">
            {items.map((item) => (
              <BioCard {...item} />
            ))}
          </div>
        </div>
        <div className="text-center mt-4-10">
          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </div>
      </ModuleWrapper>
      {/* </DragCursor> */}
    </>
  );
};

export default BioCarousel;
